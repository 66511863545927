.carousel-root {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.carouselImage {
  height: auto;
  object-fit: contain;
  /* max-height: 700px;
  width: 400px; */
  max-height: 75vh;
  /* max-width: 1200px; */
}

.carousel {
  overflow: hidden;
}
.slider img {
  /* height: 60vh; */
  /* height: 100%; */
}
.carousel .thumbs-wrapper {
  margin-inline: 0;
  overflow-x: auto;
  text-align: center;
  width: 100%;
}

.carousel .thumbs-wrapper .carouselImage {
  height: '100%';
  object-fit: cover;
  object-position: center center;
}

.carousel .thumb.selected {
  border-color: orange;
}

.carousel .thumb:hover {
  border-color: orange;
}

.carousel.carousel-slider .control-arrow {
  background: rgba(0, 0, 0, 0.2);
}
