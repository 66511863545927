.dark {
  --menu-color: #1a202c;
}

.light {
  --menu-color: #f7fafc;
}

.nav ul {
  display: none;
}

.nav-tgl {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 9999;

  width: 60px;
  height: 60px;
  border: none;
  /* border-radius: 50%; */
  padding: 0;
  /* background: #fff; */
  /* box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24); */
  line-height: 0.6;
  text-align: center;
}

/* .nav-tgl.active {
  position: absolute;
  right: 8px;
  top: 8px;
} */

.nav-tgl > span {
  display: inline-block;
  position: relative;
  height: 2px;
  width: 34px;
  border-radius: 1px;
  background: var(--menu-color);
  vertical-align: middle;
}
.nav-tgl > span:before,
.nav-tgl > span:after {
  display: inline-block;
  position: absolute;
  content: '';
  height: 2px;
  border-radius: 1px;
  background: var(--menu-color);
  transition: all 200ms;
}
.nav-tgl > span:before {
  top: -11px;
  left: 3px;
  width: 28px;
}
.nav-tgl > span:after {
  top: 11px;
  left: 6px;
  width: 22px;
}
.nav-tgl:focus {
  outline: none;
}
.nav-tgl:hover > span:after,
.nav-tgl:hover > span:before {
  width: 34px;
  left: 0;
}

.nav:before {
  --startingPosition: calc(100% - 46px);
  /* 46px = padding + center of hamburger  */
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100vw;
  height: 100vh;
  /* background: #1a202c; */
  background: rgba(0, 0, 0, 1);
  transition: all 500ms ease-in-out;
  clip-path: circle(0px at var(--startingPosition) 35px);
  visibility: hidden;
}

.menu.active .nav:before {
  visibility: visible;
  /* clip-path: circle(30px at calc(100% - 35px) 35px); */
  clip-path: circle(max(200vh, 200vw) at var(--startingPosition) 35px);
}
.menu.active .nav-tgl > span {
  height: 0;
}
.menu.active .nav-tgl > span:after,
.menu.active .nav-tgl > span:before {
  top: 0px;
  left: 0;
  width: 34px;
}
.menu.active .nav-tgl > span:after {
  transform: rotate(-45deg);
}
.menu.active .nav-tgl > span:before {
  transform: rotate(45deg);
}
