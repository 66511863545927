.frc-captcha {
  max-width: 100% !important;
}

.frc-captcha.dark {
  border-color: #3f444e !important;
}
.frc-captcha.dark button {
  background-color: #7975755e !important;
}
.frc-captcha.dark button:hover {
  background-color: #3b414c !important;
}

.frc-captcha.light {
  border-color: #e2e8f0 !important;
}

.frc-captcha.light button {
  background-color: #ddd !important;
}
.frc-captcha.light button:hover {
  background-color: #a6a2a2 !important;
}
