

.action-td {
  opacity: 0;
  /* display: none; */
  width: 100px;
}

tr:hover > .action-td {
  opacity: 1;
  /* display: block; */
}