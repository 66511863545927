.blink {
  animation: blink 2s 2;
}

.fade-in {
  animation: fade-in 2s forwards;
}

.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes rotate {
  0%{
    transform: rotate(0deg);
   }
100%{
    transform: rotate(360deg);
   }
}
